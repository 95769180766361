.main-modal-div {
    margin: 50px 0px 0px 0px;
}

.cart-sub-div {
    margin: 0px auto;
    max-width: 540px;
}

.paymentSuccessful {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.successful-Icon {
    height: 108.746px;
    flex-shrink: 0;
}

.clamping-text {
    display: -webkit-box;
    text-overflow: ellipsis;
    word-break: break-word;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.pay-success {
    color: #009DE0;
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0px;
    font-family: var(--body-family);
}

.pay-success-rtl {
    font-family: var(--font-Messiri);
    font-weight: 700 !important;
}

.fully-paid-btn {
    border-radius: 20px;
    border: 0.5px solid #FFF;
    background: rgba(255, 255, 255, 0.20);
    color: #FFF;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: var(--body-family);
    padding: 3px 16px 3px 17px;
}

.fully-paid-btn-rtl {
    font-family: var(--font-Messiri);
}

.pay-successful-main-div {
    max-width: 540px;
    text-align: center;
    padding: 2px;
}

.pay-success-parah {
    color: #748189;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.08px;
    font-family: var(--body-family);
}

.pay-success-parah-rtl {
    font-family: var(--font-Messiri);
}

.overall-experince {
    color: #0A2533;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.08px;
    font-family: var(--body-family);
}

.overall-experince-rtl {
    font-family: var(--font-Messiri);
}

.rating {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px
}

.card-bck-color {
    border-radius: 15px !important;
    border: 1px dashed #009DE0 !important;
    box-shadow: none !important;
    background: rgba(0, 157, 224, 0.80);
    background-color: rgba(0, 157, 224, 0.80) !important;
    padding: 16px 16px 0px 16px !important;

}

.rating-bck-color {
    border-radius: 15px;
    border: 1px solid #EEE;
    background: #FFF;
    margin-bottom: 20px;
    padding: 15px 0px 17px 1px;
}

.rating-stars {
    gap: 9px;
    padding: 3.229px 2.579px 3.227px 2.583px;
}

.summary-text {
    border-radius: 15px;
    border: 1px solid #EEE;
    background: #FFF;
    padding-bottom: 14px;
}

.summary-text-rtl {
    direction: rtl;
}

.get-recipt-div {
    display: flex;
    justify-content: flex-start;
}

.get-recipt-div-rtl {
    justify-content: flex-end;
}

.pay-note-img {
    width: 20px;
    height: 20px;
    margin-left: 14px;
    margin-top: 5px;
}

.pay-second-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    margin-bottom: 1px;
}

.pay-second-div-rtl {
    direction: rtl;
    font-family: var(--font-Messiri) !important;
}

.pay-second-div-sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    margin-bottom: 1px;
}

.pay-second-div-sub-rtl {
    direction: rtl;
}

.pay-second-div span {
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.58px;
    /* 139.86% */
    letter-spacing: 0.07px;
    font-family: var(--body-family);
}

.pay-second-div-rtl-span {
    font-family: var(--font-Messiri) !important;
}


.pay-second-div p {
    color: #FFF;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.you-paid {
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.58px;
    /* 130.536% */
    letter-spacing: 0.075px;
    font-family: var(--body-family);
}

.paid-rtl {
    font-family: var(--font-Messiri);
}

.paid-price {
    color: #FFF;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: var(--body-family);
}

.paid-price-rtl {
    font-family: var(--font-Messiri);
}

.pay-second-sub1 {
    display: flex;
    flex-direction: column;
}

.pay-fourth-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.order-summary {
    position: relative;
    // top: 4px;
    // font-family: var(--body-family);
}

.order-summary-rtl {
    margin-right: 15.62px;
    top: 4px;
    // font-family: var(--font-Messiri);
}

.order-summery-arrow-rtl {
    margin-left: 12px;
    transform: rotate(180deg);
}

.order-summery-arrow {
    // margin-left: 20px;
    margin: 9px 15px 0px 0px;
}

.order-summary span {
    margin-left: 20px;
    margin-top: 5px;
    color: #0A2533;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.order-summary-rtl-span {
    font-family: var(--font-Messiri);
}

.order-summary-ltr-span {
    font-family: var(--body-family);
}

.get-recipt {
    // display: 'flex';
    // justify-content: 'left';
    color: #0A2533;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 34.352px;
    /* 214.701% */
    letter-spacing: 0.08px;
    font-family: var(--body-family);
    margin-bottom: 5px;
}

.get-recipt-rtl {
    display: flex;
    justify-content: right;
    font-family: var(--font-Messiri);
    font-weight: 400 !important;
}

.email-field {
    border-radius: 15px !important;
    border: 1px solid #EEE !important;
    background: #FFF !important;
    margin-bottom: 30px !important; //50
}

.email-field-rtl {
    direction: rtl;
}

.pay-successfull-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.get-recipt-btn {
    // height: 42.746px;
    border-radius: 50px;
    border: 1px solid #009DE0;
    background: #009DE0;
    color: #FFF;
    text-align: center;
    font-family: var(--body-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    // padding: 13px 31px 12px 31px;
    padding: 10px 38px;
    display: flex;
    flex-direction: row;
}

.get-recipt-btn-rtl {
    font-family: var(--font-Messiri);
    font-weight: 700;
    padding: 8px 16px;
}

.getRecipt-Loader{
    padding-left: 10px;
}


.back-to-home {
    border-radius: 50px;
    border: 1px solid #009DE0;
    color: #009DE0;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    // padding: 13px 31px 12px 31px;
    padding: 10px 29px;
    font-family: var(--body-family);
}

.back-to-home-rtl {
    font-family: var(--font-Messiri) !important;
    font-weight: 700;
    padding: 8px 21px;
}

body.modal-open {
    overflow: hidden;
}

.cat-products-heading {
    color: #010E16;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 21px;
    margin-bottom: 18px; //18px
    font-family: var(--body-family);
}

.cat-products-heading-rtl {
    text-align: right;
    font-weight: 700 !important;
    font-family: var(--font-Messiri) !important;

}

.add-Cart {
    margin-top: -298px !important;
    position: absolute !important;
    z-index: 22;
}

// margin-Top:90px
// position:fixed;
// top:70;
// z-index: 1000;


.left-grid-modal {
    margin-left: -14px;
}

.cart-div {
    width: 100%;
    z-index: 9999;
    position: fixed;
    top: 105px;
    left: 0px;
    height: 100vh !important;
    border-radius: 40px 40px 0px 0px;
    background: #FFF;
    box-shadow: none !important;
    // padding: 2px 25px 14px;
}

@media(min-width:768px) {
    .cart-div {
        width: 540px;
        left: auto;
    }
}

.qty-style {
    cursor: pointer;
    font-family: var(--body-family);
    font-size: 12px;
    font-weight: 500;
    padding: 6px !important;
    border-radius: 8px !important;
    background: #F1F5F5 !important;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    position: relative;
    top: 5px;
}

.qty-style-rtl {
    padding: 6px !important;
    margin-left: 10px !important;
    border-radius: 8px !important;
    background: #F1F5F5 !important;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.main-bill-div {
    display: flex;
    justify-content: space-between;
    margin: 25px 14px 25px 0px;
    padding: 2px 13px 0px 25px;
    // align-items: center;
}

.main-bill-div-rtl {
    direction: rtl;
    display: flex;
    justify-content: space-between;
    margin: 25px 0px 25px 0px;
    padding: 2px 25px 0px 25px;
}

.cart-doted-line-div {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    // margin-top: 25px;
    padding: 0px 25px 10px;
    margin-bottom: 15px; //10px;
}

.cart-payment-sub-div1 {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.cart-payment-sub-div1-small-screen {
    display: flex;
    justify-content: space-between;
    // margin-top: 20px;
}

.cart-payment-sub-div2 {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.cart-payment-sub-div2-small-screen {
    display: flex;
    justify-content: space-between;
    // margin-top: 20px;
}

.cart-payment-div {
    // padding: 2px 25px 14px;
    padding: 0px 25px 0px 25px;
}

.cart-payment-div-rtl {
    padding: 2px 25px 0px 25px;
    direction: rtl;
}

.cart-pay-vat {
    color: #009DE0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    font-family: var(--body-family);
    line-height: 19.58px;
    /* 139.86% */
    letter-spacing: 0.07px;
}

.cart-pay-vat-rtl {
    font-family: var(--font-Messiri);
}

.currency {
    color: #009DE0;
    text-align: right;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

// .cart-doted-line-div-img{
.sar-price {
    color: #009DE0;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: var(--body-family);
    margin-left: 3px;
}

.riapay-price {
    color: #748189;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.58px;
    /* 139.86% */
    letter-spacing: 0.07px;
    font-family: var(--body-family);
    margin-left: 0px;
}

.riapay-price-rtl {
    font-family: var(--font-Messiri);
}

.riapay-sar {
    color: #748189;
    text-align: right;
    font-size: 9px;
    font-weight: 500;
    line-height: normal;
    font-family: var(--font-Messiri);
}

.payable-amount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px; //40
    background: rgba(0, 157, 224, 0.05);
    // padding: 10px 0px 10px 0px;
    padding: 13px 0px;
    font-family: var(--body-family);
}

.payable-amount-rtl {
    direction: rtl;
    padding: 16px 21px 18px 21px;
    font-family: var(--font-Messiri);
}

.payable-amount-small-screen {
    margin-top: 20px;
}

.payable-amount-very-small-screen {
    margin-top: 5px;
}


.payable-amount-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 59px;
    // margin-top: 40px;
    background: rgba(0, 157, 224, 0.05);
    padding: 16px 0px 10px 0px;
}

.payable-amount-div-rtl {
    direction: rtl;
    padding: 16px 21px 16px 21px !important;
}

.paya-head {
    margin: 0px 0px 5px;
    color: #0A2533;
    font-size: 11px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.055px;
    padding-right: 0px;
    font-family: var(--body-family);
}

.paya-head-rtl {
    font-family: var(--font-Messiri);
    font-weight: 400 !important;
}

.paya-parah {
    margin: 0px 0px 0px;
    color: #748189;
    font-size: 11px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.055px;
    font-family: var(--body-family);
}

.paya-parah-rtl {
    font-family: var(--font-Messiri);

}

.paya-price {
    color: #009DE0;
    text-align: right;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    padding-right: 25px;
    font-family: var(--body-family);
}

.paya-price-payfully {
    color: #009DE0;
    text-align: right;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    padding-right: 5px;
    font-family: var(--body-family);
}

.paya-price-amount {
    color: #009DE0;
    text-align: right;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    padding-right: 25px;
    font-family: var(--body-family);
}

.paya-price-rtl {
    font-family: var(--font-Messiri);
}

.radio-form-main-pay {
    position: relative;
    border-top: 1px dotted #EBEBEB;
    margin-bottom: 0px;
    display: flex !important;
    align-items: center !important;
    padding: 8px 0px; //13
}

.flag-inner-div-pay {
    display: flex;
    align-items: center;
    text-align: center;
}

.pay {
    margin: 0px;
}

.flg-div-pay {
    display: inline-block;
    margin-left: 35px;
    // width: 40px;
    // height: 40px;
    // border-radius: 10px;
    // background-color: grey;
}

.payment-method-head {
    color: #0A2533;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 34.352px;
    /* 214.701% */
    letter-spacing: 0.08px;
    padding-left: 24px; //32px;
    font-family: var(--body-family);
}

.payment-method-head-rtl {
    direction: rtl;
    padding-right: 20px;
    padding-left: 0px;
    font-family: var(--font-Messiri);
}

.payment-method-div {
    padding: 0px 21px 0px 21px;
}

.payment-type-div {
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid #DEDEDE;
    background: #FFF;
    margin-bottom: 10px;
}

.payment-button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 30px; //60
}

.terms-div-rtl {
    direction: rtl;
    // font-family: var(--font-Messiri);
}

.terms-div p {
    color: #0A2533;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 15px;
    margin-top: 39px;
    text-transform: capitalize;
    font-family: var(--body-family);

}

.terms-div-rtl {
    font-family: var(--font-Messiri) !important;
}

.terms-div .terms-span {
    color: #009DE0;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    font-family: var(--body-family);
    margin-left: 3px;
}

.terms-div-span-rtl {
    font-weight: 500;
}

.pay-btn-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    padding: 0px 22px 0px 23px;
}

.pay-btn-with {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: #042628;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    padding: 13px 90px;
    border: none;
    font-family: var(--body-family);
    width: 100%; ///347px;
    cursor: pointer;
}

.paywith-main-loader {
    display: flex;
    position: relative;
}

.pay-loader {
    position: absolute;
    right: -31px;
}

.pay-btn-with-rtl {
    font-family: var(--font-Messiri) !important;
}

.payment-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px 0px 20px 20px;
    border-bottom: 5px #D9D9D9;
    background: #FFF;
    height: 81px;
    flex-shrink: 0;
    padding: 0px 20px 0px 20px;
    // font-family: var(--body-family);
}

.payment-btn-second-payfully-modal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px 0px 20px 20px;
    border-bottom: 5px #D9D9D9;
    background: #FFF;
    height: 81px;
    flex-shrink: 0;
    padding: 0px 15px 0px 15px;
    // padding: 0px 20px 0px 20px;
    // font-family: var(--body-family);
}


.payment-btn2-rtl-payfully {
    font-family: var(--font-Messiri) !important;
    font-weight: 700 !important;
    padding: 9px 42px !important;
}

.payment-btn1-rtl-payfully {
    font-family: var(--font-Messiri) !important;
    font-weight: 700 !important;
    padding: 12px 35px !important;
}



.payment-button2-CartInner-rtl {
    font-family: var(--font-Messiri) !important;
    font-weight: 700 !important;
    padding: 9px 37px !important;
}

.payment-button-btn1-payFully {
    // padding: 12px 42px 12px 42px;
    // padding: 10px 50px;
    // padding: 10px 51px;
    // padding: 10px 51px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid #009DE0;
    background: rgba(0, 157, 224, 0.05);
    color: #009DE0;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    // font-weight: 500;
    line-height: normal;
    font-family: var(--body-family);
    font-weight: 400 !important;
    padding: 10px 50px;
    cursor: pointer;
}

.payment-button1-CartInner {
    padding: 10px 51px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid #009DE0;
    background: rgba(0, 157, 224, 0.05);
    color: #009DE0;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: var(--body-family);
    cursor: pointer;
}

.payment-button-btn1-rtl {
    font-family: var(--font-Messiri) !important;
    font-weight: 700 !important;
    padding: 8px 41px !important;
}

.payment-button1-CartInner-rtl {
    font-family: var(--font-Messiri) !important;
    cursor: pointer;
    font-weight: 700 !important;
    padding: 8px 41px !important;
}


.second-modal-payment-button-rtl {}




.payment-button-btn2-payFully {
    cursor: pointer !important;
    font-family: var(--body-family);
    flex-shrink: 0;
    // padding: 12px 42.65px 12.75px 42.95px;
    // padding: 11px 49px;
    border-radius: 50px;
    background: #009DE0;
    border: none;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    font-size: 16px !important;
    font-weight: 500 !important;
    padding: 13px 45px !important;
}

.payment-button2-CartInner {
    cursor: pointer !important;
    font-family: var(--body-family);
    flex-shrink: 0;
    // padding: 12px 42.65px 12.75px 42.95px;
    padding: 11px 49px;
    border-radius: 50px;
    background: #009DE0;
    border: none;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.btn-rounded-border {
    border-radius: 0px 0px 20px 20px;
    border-bottom: 1px solid #D9D9D9;
    background: #FFF;
    // padding: 10px;
}

.tip-div {
    padding: 2px 25px 0px 16px;
}

.tip-heading {
    color: #0A2533;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 34.352px;
    /* 214.701% */
    letter-spacing: 0.08px;
    font-family: var(--body-family);
    margin-bottom: 7.19px;
}

.tip-heading-rtl {
    font-family: var(--font-Messiri);
    direction: rtl;
    font-weight: 400 !important;
}

.app-bar-rtl {
    direction: rtl;
}

.percent-div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.pecent-div-span {
    display: flex !important;
    margin-left: 12px !important;
    width: 89px;
    height: 42px;
    padding: 13px 0px !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 20px !important;
    border: 0.5px solid #009DE0 !important;
    background: rgba(0, 157, 224, 0.20) !important;
    // margin-right: 20px !important;
}

.other-tab-div {
    display: flex !important;
    margin-left: 12px !important;
    width: 89px;
    height: 42px;
    padding: 13px 0px !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 23.929px !important;
    background: #F1F5F5 !important;
    // margin-right: 20px !important;
}

.tip-value {
    color: #009DE0 !important;
    text-align: right !important;
    font-family: var(--body-family) !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    height: 42px;
    width: 89px;
}

.tip-value-in-active {
    color: #748189 !important;
    text-align: center !important;
    font-family: var(--body-family) !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 24px;
    /* 200% */
    letter-spacing: 0.06px;
    height: 42px;
    width: 89px;
}

.tip-amt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 25px 0px 25px;
    font-family: var(--body-family);

}

.tip-amt-rtl {
    direction: rtl;
}

.tip-amt-arabic {
    font-family: var(--font-Messiri);
}

.tip-amt h4 {
    color: #009DE0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.58px;
    /* 139.86% */
    letter-spacing: 0.07px;
}

.tip-amt p {
    color: #009DE0;
    text-align: right;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.tip-amt-span {
    color: #009DE0;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.bill-item {
    position: relative !important;
    overflow: hidden !important;
    width: 100% !important;
    // height: 258px !important;
}



// .cart-doted-line-div-img{
//     width:100px;
// }
.sub-bill-div {
    display: flex;
    // align-items: center;
}

.bill-heading-div {
    margin-left: 14px;
}

.bill-heading {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: var(--body-family);
    color: #0A2533;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.58px;
    /* 139.86% */
    letter-spacing: 0.07px;
}

.bill-heading-rtl {
    font-family: var(--font-Messiri);
}

.bill-parah {
    margin: 0px;
    color: #748189;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    font-family: var(--body-family);
    line-height: 19.58px;
    /* 195.804% */
    letter-spacing: 0.05px;
}

.bill-parah-rtl {
    font-family: var(--font-Messiri);
    // direction: rtl;
}

.Add-On {
    color: #748189;
    font-family: Outfit;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.58px;
    /* 195.804% */
    letter-spacing: 0.05px;
}

.Add-On-Item {
    color: #748189;
    font-family: Outfit;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.58px;
    letter-spacing: 0.05px;
}

.Add-on-price {
    color: #009DE0;
    font-family: Outfit;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.58px;
    /* 195.804% */
    letter-spacing: 0.05px;
}

.Add-on-price-rtl {
    font-family: var(--font-Messiri);
}

.item-total-price-div {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.item-total-price {
    color: #009DE0;
    font-family: var(--body-family);
    text-align: right;
    font-size: 9px;
    font-weight: 500;
    line-height: normal;
}

.item-total-price span {
    color: #009DE0;
    font-family: var(--body-family);
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin-left: 3px;
}

.item-total-price-rtl {
    font-family: var(--font-Messiri);
}

// .cart-div {

// width: 415px; //466
// border: 1px solid var(--card-div-gains-boro);
// z-index: 200;
// padding: 2px 25px 14px;
// box-sizing: border-box;
// border-radius: 20px;

// height: 710px;
// height: 818px;
// border: 1px solid #DCE1E5;
// box-shadow: 0px 0px 24px 0px rgba(27, 107, 197, 0.10) !important;
// cursor: pointer;
// position: fixed;
// top: 250px;
// right: 87px;
// }

.cartScrollbar {
    height: 500px !important;
}

.cartimage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    flex-flow: column;
}

// @media(max-width:1920px){
//     .cart-div{
//         height: 710px;
//     }
//     .cartScrollbar{
//         height: 500px !important;
//     }
// }

@media (min-width:600px) {
    // .pecent-div-span {
    //     min-width: 108px !important;
    // }
}

@media (max-width: 1920px) {
    // .cart-div {
    //     height: 570px;
    //     width: 390px;
    // }

    .cartScrollbar {
        height: 360px !important;
    }
}

@media(max-width:1500px) {
    .cart-div {
        // height: 550px;
    }

    .cartScrollbar {
        height: 330px !important;
    }
}

@media(max-width:1366px) {
    .cart-div {
        // height: 545px !important;
        // width: 390px;
    }

    .cartScrollbar {
        height: 340px !important;
    }
}

@media (max-width: 960px) {
    // .cart-div {
    //     width: 100%;
    //     z-index: 9999;
    //     padding: 2px 25px 14px;
    //     position: fixed;
    //     top: 105px;
    //     left: 0px;
    //     height: 100vh !important;
    //     border-radius: 40px 40px 0px 0px;
    // }

    .cart-sticky {
        position: static;
        background: #fff;
    }

    .cartScrollbar {
        height: 70vh !important;
    }
}

@media (max-width: 767px) {
    .cartScrollbar {
        height: 65vh !important; //75
    }



}

@media (max-width: 576px) {
    .openCartBtn {
        width: 100% !important;
        left: 0px;
    }
}

@media (max-width: 380px) {
    .cartScrollbar {
        height: 67vh !important;
    }

    // .cross-svg-style {
    //     right: 2px !important;
    // }

    // .cross-svg-style-cart-detail {
    //     right: -10px !important;
    // }
}

@media (max-width:350px) {
    .menu-cart-box-style {
        width: 278px !important;
    }

    .price-span {
        font-size: 10px !important;
    }

    .cartScrollbar {
        height: 52vh !important;
    }

    .card-content-divs {
        padding: 0px 5px 0px 0px !important;
    }

    // .cross-svg-style-cart-detail {
    //     right: -10px !important;
    // }

    // .cross-svg-style-cart-detail {
    //     right: -10px !important;
    // }
}

.table-heading {
    color: #0A2533;
    font-size: 20.611px;
    font-weight: 500;
    line-height: 34.352px;
    font-family: var(--body-family);
    letter-spacing: 0.103px;
    margin-bottom: 0px;

}

.table-heading-rtl {
    font-family: var(--font-Messiri);
    font-weight: 700 !important;
}

.cart-head-cross {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2px 25px 0px 25px;
}

.cart-head-cross-rtl {
    direction: rtl;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2px 25px 0px 25px;
}

.cross-svg-style {
    // padding-top: 25px;
    width: 22px;
    height: 22px;
    padding-top: 20px;
    cursor: pointer;
    // position: absolute;
    // right: 20px;
    // top: 15px;
}

.second-modal-cross-svg-style {
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.cart-head-cross-pay {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0px;
    // padding: 0px 30px 0px 15px;    
}

.cart-head-cross-pay-rtl {
    direction: rtl;
}

.cross-svg-style-cart-detail {
    position: absolute;
    right: 24px;
    top: 204px;
    display: flex;
    padding: 6px 12px 6px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background: rgba(0, 0, 0, 0.40);

    color: var(--text-content-secondary-night, #FFF);
    text-align: center;
    /* Base/14px - Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 21px */
}

.detail-card {
    margin-top: 15px; //23
    padding: 16px 16px;
    border-radius: 8px !important;
    background: #FFF;
    background: var(--background-panel-day, #FFF);
    box-shadow: none !important;
    border: none;
}

// .modal-bg-gradiant{
//     position: absolute;
//     left: 0px;
//     right: 0px;
//     top: 0px;
//     width: 100%;
//     height: 100%;
//     background: linear-gradient(0deg, rgba(0, 157, 224, 0.05) 0%, rgba(0, 157, 224, 0.05) 100%), #FFF;

// }
.cross-svg-style-cart-detail-rtl {
    position: absolute;
    // right: 20px;
    top: -10px;
}

.MuiBadge-colorPrimary {
    // background-color: #1B6BC5 !important;
    background-color: var(--btn-primary-blue-bg) !important;
}

.cart-item-mdd {
    text-align: center;
    // margin: 100px 0px 175px 0px
}

.hungry-heading {
    // color: #000;
    color: var(--menu-item-btn-black);
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    /* 144.444% */
}

.hungry-para {
    // color: #636363;
    color: var(--paragraph--text-dim-gray);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 144.444% */
}

.no-of-item {
    cursor: pointer;
    width: 40px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 7px;
    // border: 1px solid #1B6BC5;
    border: 1px solid var(--btn-primary-blue-bg);
    background: #FFF;
}

.no-of-item .MuiInput-underline:after,
.no-of-item .MuiInput-underline:before {
    display: none;
}

.no-of-item .MuiSelect-select.MuiSelect-select {
    border-radius: 7px;
    border: 1px solid #1B6BC5;
    background: #FFF;
    padding: 2px 3px;
}

.cart-outer {
    position: absolute;
    bottom: 58px;
    right: -160px;
}

.cart-text-btn {
    cursor: pointer;
    margin-top: 30px;
    text-align: center;
    box-shadow: 0px 0px 24px 0px rgba(27, 107, 197, 0.1);
    display: inline-block;
    border-radius: 8px;
    background: #FFF;
    padding: 4px;
}

.cart-outer-btn {
    text-align: center;
}

.cart-logo {
    margin-top: -53px;
    background: #fff;
    padding: 7px;
    border-radius: 100%;
}

.cart-logo-image {
    width: 89px;
    height: 89px;
    border-radius: 100%;
}

.cart-main-div-head h2 {
    // color: var(--menu-item-btn-black);
    // font-size: 18px;
    // font-weight: 400;
    // line-height: 26px;
    // margin: 30px 0px 20px 0px;
}

.cart-main-div-head-rtl h2 {
    color: var(--menu-item-btn-black);
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin: 30px 18px 20px 0px; //230px margin-left:230px;
    text-align: right;
}


.cart-text-btn button {
    border-radius: 8px;
    // background: #1B6BC5;
    background: var(--text-color-blue);
    border: none;
    padding: 13px 72px;
    color: var(--text-color-white);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
}

// .menu-cart-box-style {
//     padding: 10px;
//     margin-bottom: 20px;
//     border-radius: 20px !important;
//     box-shadow: 0px 2.909px 13.573px 0px rgba(0, 0, 0, 0.10) !important;
// }
.menu-cart-box-style {
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 20px !important;
    box-shadow: none !important;
    box-sizing: border-box;
    align-items: normal !important;
    margin-left: 0px;
    margin-right: 12px;
    border: 1px solid #F3F3F3;
    height: 104px !important;
}

.menu-cart-box-style img {
    width: 84px;
    height: 84px;
    border-radius: 12px;
}

.menu-cart-box-style .no-img {
    width: 84px;
    height: 84px;
    background: #efefef;
    border-radius: 17px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.card-content-divs {
    flex-grow: 1;
    padding: 0px 6px 0px 14px !important;
    display: flex;
    flex-flow: column;
}

.card-content-divs-rtl {
    display: flex !important;
    flex-direction: row;
    flex-grow: 1;
    padding: 0px 14px 0px 6px !important;
}

.item-price-value {
    display: flex;
    justify-content: space-between;
    //flex-direction: row-reverse;
    flex-grow: 1
}

.item-price-value-rtl {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    flex-grow: 1
}

.itm-dtl-price {
    color: var(--text-color-blue) !important;
    align-content: end;
    flex-grow: 1;
}

.price-span {
    // color: #0065D1;
    color: var(--text-color-blue) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: right;
}

.price-span-rtl {
    margin: 15px -35px 0px 0px !important;
    // margin: 7px -100px 0px 0px !important;
}

.price-tag-span {
    color: var(--text-color-blue) !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: right;
}

.cart-name-text-detail-rtl {
    margin: 6px 10px 0px 0px;
}

.cart-name-text-detail p {
    // color: #979797 !important;
    columns: var(--text-color-gray) !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: normal !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    display: -webkit-box !important;
    overflow: hidden;
    text-overflow: ellipsis !important;
    word-break: break-word;
    margin-bottom: 5px;
    margin-top: 9px;

}

.cart-name-text-detail-rtl p {
    // color: #979797 !important;
    columns: var(--text-color-gray) !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: normal !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    display: -webkit-box !important;
    overflow: hidden;
    text-overflow: ellipsis !important;
    word-break: break-word;
    margin-bottom: 5px;
    margin-top: 9px;
    text-align: right;
}


.cart-name-text-detail h2 {
    color: var(--text-color-dark) !important;
    margin: 4px 0px 0px 0px;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-style: normal;
    line-height: normal !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis !important;
    word-break: break-word;
}

.cart-name-text-detail-rtl h2 {
    color: var(--text-color-dark) !important;
    margin: 4px 0px 0px 0px;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-style: normal;
    line-height: normal !important;
    text-align: right;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis !important;
    word-break: break-word;
}


.dishes-menu-related {
    border-radius: 15px !important;
    background: #FFF;
    // box-shadow: 0px 0px 20.033px 0px rgba(27, 107, 197, 0.1) !important;
    box-shadow: none !important;
    border: 1px solid #F3F3F3;
    padding: 8px;
    //width: 116px;
    height: 185px;
}

.card-content {
    padding: 14px 0px 0px 0px !important;
}

.menu-title-related {
    // color: #010E16;
    color: var(--text-color-dark);
    font-size: 12px !important;
    font-weight: 300 !important;
    line-height: normal !important;
    // margin-bottom: 12px !important;
    // font-size: 12px;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: normal !important;
}

.div-menu-related-img {
    height: 116px;
    object-fit: cover;
    overflow: hidden;
    position: relative;
}

.Related-item-addBtn {
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 18px;
    right: 5px;
}

.summary-div-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;

}

.cart-dashed-total {
    overflow: hidden;
    position: relative;
    text-align: center;
    padding: 2px;
    margin-bottom: 0px;
}

.cart-dashed-total:before {
    content: "";
    position: absolute;
    border-top: 3px dashed #D9D9D9;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
}

.bill-sum-style h2 {
    color: var(--menu-item-btn-black);
    font-size: 16px;
    font-weight: 500;
    line-height: 19.58px;
    letter-spacing: 0.08px;
    margin: 5px 0px 15px 0px;
}

.bill-sum-style-rtl h2 {
    color: var(--menu-item-btn-black);
    font-size: 16px;
    font-weight: 500;
    line-height: 19.58px;
    letter-spacing: 0.08px;
    // margin: 5px 15px 15px 270px;
    margin: 5px 14px 15px 0px;

}

.left-span {
    // color: #979797;
    color: var(--text-color-gray);
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.06px;
}

.right-span {
    // color: var(--Grayscale-Grayscale-100, #02050F);
    color: var(--span-text-color-rick-black);
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.58px;
    letter-spacing: 0.07px;
    padding-right: 15px;
}

.right-span-rtl {
    // color: var(--Grayscale-Grayscale-100, #02050F);
    color: var(--span-text-color-rick-black);
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.58px;
    letter-spacing: 0.07px;
    margin-right: 15px;
}

.dashes-style {
    // background: repeating-linear-gradient(90deg, #dce1e5, #dce1e5 6px, transparent 0, transparent 12px);
    background: repeating-linear-gradient(90deg, var(--card-div-gains-boro), var(--card-div-gains-boro) 6px, transparent 0, transparent 12px);

    border: none;
    height: 1px;
}

.left-span-total {
    // color: #000;
    color: var(--menu-item-btn-black);
    font-size: 16px;
    font-weight: 500;
    line-height: 19.58px;
    letter-spacing: 0.08px;
}

.right-span-total {
    // color: #1B6BC5;
    color: var(--btn-primary-blue-bg);
    text-align: right;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.58px;
    letter-spacing: 0.08px;
}

.btn-payment {
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.btn-payment button {
    cursor: pointer;
    // color: #FFF;
    color: var(--text-color-white);
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    border-radius: 50px;
    // background: #1B6BC5;
    background: var(--text-color-blue);
    border: none;
    padding: 12px 60px;
    margin: 10px 0px 5px 0px;
    width: 100%;
    height: 49.761px;
    flex-shrink: 0;
}

.button-payment-disable {
    opacity: 0.5;
}

@media(max-width:500px) {
    .btn-payment button {
        width: 100%;
    }
}

// @media(max-width:760px){
//     .btn-payment button {
//         width: 100%;
//     }
// }

// @media (max-width:960px){
//     .btn-payment button {
//         width:100%;
//     }
// }
.openCartBtn {
    position: fixed;
    bottom: 0px;
    width: 490px;
    height: 62px;
    // border-radius: 10px 10px 0px 0px;
    border-radius: 20.65px 20.65px 0px 0px;
    background-color: rgba(0, 157, 224, 0.80); //var(--btn-primary-blue-bg) !important;
    box-shadow: none !important;
    border: 1px solid #F3F3F3;
    cursor: pointer;
    z-index: 1;
}


.openCartBtn.in {
    display: block;
}

.openCartBtnText {
    color: #FFF;
    font-size: 14.571px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    font-family: var(--body-family);
}

.openCartBtnText-rtl {
    font-family: var(--font-Messiri);
}

.open-cart-inner {
    display: flex;
    color: #fff;
    width: 100%;
    justify-content: space-around;
    border-radius: 20.65px 20.65px 0px 0px;
    border-top: 1.033px rgba(0, 157, 224, 0.20);
    background: rgba(0, 157, 224, 0.80);
    backdrop-filter: blur(2.5812816619873047px);
    align-items: center;
    padding: 0px 20px;
    box-sizing: border-box;
    height: 60px //62px;
}

.open-cart-inner div {
    flex-grow: 1;
}

.openCartBtnItems {
    border-radius: 100%;
    border: 1px solid #fff;
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    font-size: 12.89px;
    font-weight: 400;
}

.openCartBtnPrice {
    text-align: right;
}

.openCartBtnPriceHead {
    color: #FFF;
    text-align: right;
    font-size: 9.003px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: var(--body-family);
}

.openCartBtnPriceValue {
    color: #FFF;
    font-size: 14.006px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: var(--body-family);
}

.cart-sticky {
    position: relative;
    left: 0px;
    width: calc(100% - 0px);
    bottom: 0px;
    /* height: 190px; */
    margin: 0px auto;
}

.payModal-div {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.payModal-div-rtl {
    display: flex;
    flex-direction: row-reverse;

}

.applePay {
    display: flex;
    padding: 20px 0px 0px 22px;
}

.applePay-rtl {
    padding: 25px 22px 0px 0px;
}

.appleIcon {
    width: 21px;
    height: 25.667px;
}

.applePay-head {
    color: #0A2533;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 12px;
}

.paymodal-cartCross {
    position: absolute;
    right: 22px;
    top: 25px;
}

.paymodal-cartCross-rtl {
    left: 22px;
    right: 0px;
}

.items-list {
    height: 400px;
}

.payModal-second-div {
    // width: 352px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid #EEE;
    background: #FFF;
    margin: 25px 25px 20px 23px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.payModal-second-div-rtl {
    direction: rtl;
}

.masterIcon {
    width: 40px;
    height: 27px;
    flex-shrink: 0;
    margin: 17px 0px 0px 12px;
}

.masterIcon-rtl {
    margin: 17px 12px 0px 12px !important;
}

.paymodal-div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.paymodal-text {
    display: grid;
    margin: 12px 0px 0px 17px;
}

.forward-Arrow {
    margin: 17px 23px 22px 1px;
}

.forward-Arrow-rtl {
    margin: 17px 1px 22px 23px;
    transform: rotate(181deg);
}

.payModal-third-div {
    // width: 352px;
    height: 82.385px;
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid #EEE;
    background: #FFF;
    // margin: 25px 25px 10px 23px;
    margin: 17px 25px 10px 23px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.payModal-third-div-rtl {
    direction: rtl;
}

.contactIcon {
    // background: url(<path-to-image>) lightgray 50% / cover no-repeat;
    width: 28.5px;
    height: 28.5px;
    flex-shrink: 0;
    margin: 13px 0px 0px 13px;
}

.contactIcon-rtl {
    margin: 13px 13px 0px 17px !important;
}

.contact-div {
    display: flex;
    flex-direction: row;
}

.contact-text-div {
    display: grid;
    margin: 12px 0px 0px 17px;
}

.paymodal-t {
    color: #0A2533;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.06px;
    font-family: var(--body-family);
}

.paymodal-t-rtl {
    font-family: var(--font-Messiri);
}


.paymodal-text-number {
    color: #748189;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.055px;
    font-family: var(--body-family);
}

.contact-text {
    color: #748189;
    font-size: 13.356px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: var(--body-family);
}

.contact-text-rtl {
    font-family: var(--font-Messiri);
}

.contact-number {
    color: #0A2533;
    font-size: 16.695px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: var(--body-family);
}

// .contact-number-rtl {
//     font-family: var(--font-Messiri);
// }

.contact-email {
    color: #748189;
    font-size: 15.582px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: var(--body-family);
}


.paymodal-payment-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.paymodal-payment-div-rtl {
    direction: rtl;
}

.final-amt {
    color: #748189;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.055px;
    font-family: var(--body-family);
}

.final-amt-rtl {
    font-family: var(--font-Messiri);
}

.final-price {
    color: #009DE0;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0px;
    font-family: var(--body-family);
}

.payment-div-text {
    margin-left: 30px;
}

.payment-div-text-rtl {
    margin-right: 30px;
    margin-left: 0px;
}

.payButton-div {
    margin: 18px 30px 0px 0px;
}

.payButton-div-rtl {
    margin: 18px 0px 0px 30px;
}

.payButton {
    border-radius: 44.733px;
    background: #009DE0;
    width: 106.207px;
    height: 38.689px;
    flex-shrink: 0;
    border: none;
    font-family: var(--body-family);
    //for btn text
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normalpa;
}

.payButton-rtl {
    font-family: var(--font-Messiri);
    font-weight: 700;
}

.payment-option-scrollbar {
    // height: 750px !important;
}


@media(max-width:320px) {
    // .bill-item {
    //     height: 75px !important;
    // }


    // .payment-option-scrollbar {
    //     height: 450px !important;
    // }

}

@media(min-width:321px) and (max-width:400px) {
    // .bill-item {
    //     height: 153px !important;
    // }

    .payment-button {
        margin-top: 34px;
    }



}

@media(min-width:360px) {
    // .payment-option-scrollbar {
    //     height: 585px !important; //500
    // }
}

@media(min-width:375px) {
    // .payment-option-scrollbar {
    //     height: 505px !important; //500
    // }
}

@media(min-width:390px) {
    // .payment-option-scrollbar {
    //     height: 685px !important; //500
    // }
}

@media(min-width:391px) and (max-width:412px) {
    // .payment-option-scrollbar {
    //     height: 600px !important; // 730px !important; //580
    // }
}

@media(min-width:413px) {
    // .payment-option-scrollbar {
    //     height: 580px !important; //685
    // }
}

@media(min-width:420px) and (max-width:428px) {
    // .payment-option-scrollbar {
    //     height: 725px !important; //685
    // }
}

@media(min-width:430px) {
    // .payment-option-scrollbar {
    //     height: 575px !important;
    // }
}



// @media(min-width:360px) and (max-width:390px) {
//     .payment-option-scrollbar {
//         height: 564px !important;//500
//     }
// }

// @media(min-width:374px) and (max-width:380px) {
//     .payment-option-scrollbar {
//         height: 450px !important;//500
//     }
// }

// @media(min-width:381px) and (max-width:390px) {
//     .payment-option-scrollbar {
//         height: 672px !important;//500
//     }
// }

// @media(min-width:391px) and (max-width:412px){
//     .payment-option-scrollbar {
//         height: 580px !important; //720px !important;
//     }
// }

@media(min-width:414px) and (max-width:429px) {
    // .bill-item {
    //     height: 182px !important;
    // }

    // .payment-option-scrollbar{
    //     height: 562px !important;//555px
    // }
}

// @media(min-width:401px) and (max-width:429px){
//     .bill-item{
//         height:  180px !important;
//     }
// }

@media(min-width:430px) {
    // .bill-item {
    //     height: 182px !important;
    // }

    // .payment-option-scrollbar{
    //     height: 535px !important;//555px
    // }
}

//quantity-heandler css
.quantityHandler-main-class {
    border-radius: 8px;
    border: 1px dashed rgba(0, 157, 224, 0.50);
    background: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(10px);
    margin-left: 15px;
    max-height: 30px;
}

.quantityHandler-sub-class {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.minusbtn-cart {
    // display: flex;
    // width: 24px;
    // height: 24px;
    // padding: 10px;
    // justify-content: center;
    // align-items: center;
    gap: 10px;
    border-radius: 26px;
    background: #FFF;
    cursor: pointer;
}

.quantity-value {
    // padding: 5px 10px 0px 10px;
}