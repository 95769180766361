.open-cart-icon {
    position: absolute;
    top: 370px;
    right: 100px;
    cursor: pointer;
    transform: rotate(183deg);
    -webkit-transform: rotate(183deg);
}

.header--hidden {
    transform: translateY(-100%);
}

.header--visible {
    transform: translateY(0);
}

.wrapper-center,
.center-item {
    max-width: 540px !important;
    margin: 0px auto;
}

.wrapper-center .MuiContainer-maxWidthLg {
    max-width: 540px !important;
}

.headerLeftArrow {
    cursor: pointer;
}

.profile-header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.riyaPay-logo {
    background: #fff;
    padding: 13px;
    position: relative;
    z-index: 999999;
    margin-top: -53px;
    border-radius: 100%;
}

.riyaPay-logo-outer {
    margin-top: -83px ;
}
.riyaPay-logo-small {
    margin-top: -185px !important;
}


.riyaPay-logo1 img {
    border-radius: 100%;
    height: 150px; //80px;
    width: 150px; //80px;
    // object-fit: none;
}

.riyaPay-logo-1 {
    padding:7.81px !important;
}

.riyaPay-logo-main img {
    width: 88px;
    height: 88px;
    border-radius: 100%;
}


.restaurant-image-div {
    position: relative;
    height: 253px;
    min-height: 253px;
}


.restaurant-image-div-1 img {
    border-radius: 0 0 80px 80px;
    height: 189px;
    width: 100%;
    object-fit: cover;
    filter: blur(2.5px);
}


.restaurant-image-div-2 img {
    // max-height: 259px;
    width: 100%;
    height: 259px;
    border-radius: 0px 0px 84px 84px;
    object-fit: cover;
    filter: blur(2.5px);
}
.restaurant-image-div-2-small img {
    height: 170px;
}

.riyaPay-logoss {
    background: #fff;
    padding: 7px;
    position: relative;
    z-index: 999999;
    margin-top: 18px;
    border-radius: 100%;
}

.header-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.open-cart-icon.in {
    right: 540px;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}

.drawe-style-mob .MuiAvatar-root {
    width: 22px;
    height: 22px;
    border: 2px solid #fff;
    outline: 2px solid #3384FF;
}


.navbar-transparent-fixed {
    background: transparent !important;
    box-shadow: none !important;
    transition: all 0.5s ease !important;
    -webkit-transition: all 0.5s ease !important;
    border-bottom: 1px solid transparent;
}

.display-emp button {
    cursor: pointer;
    // color: #FFF;
    color: var(--text-color-white);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    padding: 13px 12px !important;
    border: none;
    border-radius: 50px;
    // background: #1B6BC5;
    background: var(--btn-primary-blue-bg);
}

.display-emp-rtl button {
    font-size: 10px !important;
}

.header-bar {
    /* padding-left: 0px !important; */
    /* padding-right: 0px !important; */
    position: absolute !important;
    left: 0px;
    top: 0px;
    z-index: 99;
    width: 100%;
}

.profile-div {
    position: fixed;
    top: 95px;
    right: 64px;
    width: 372px;
    border: 1px solid #DCE1E5;
    z-index: 200;
    padding: 34px 26px 21px 25px;
    box-sizing: border-box;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.10);
    cursor: pointer;

}

.container-styles {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cart-res {
    cursor: pointer;
    // position: absolute;
    // right: 3px; //24px;
    // top: -2px;
}

.text-styles {
    margin-right: 18px; // Adjust the margin as needed
    font-size: 16px; // Set the font size for the text
}

.border-input {
    // border: 1px solid var(--Color-3-CW-Grey-L2, #E8E8E8);
    border: 1px solid var(--Color-3-CW-Grey-L2, var(--border-color));
    background: var(--Color-1-CW1-White-N, #FFF);
}

.navbar-white {
    background: transparent !important;
    // background: #fff !important;
    box-shadow: 0 0 0px 0 var(--boxshadow-color) !important;
    transition: all 0.5s ease !important;
    -webkit-transition: all 0.5s ease !important;

}

.navbar-white-fixed {
    // background: rgba(255, 255, 255, 0.8) !important;
    background: #fff !important;

}

.header-height-max {
    height: 189px;
}

.header-height-max2 {
    height: 259px;
}

// .navbar-white-fixed .display-emp button {
//     background: #FFFFFF !important;
//     border: 1px solid #D8DCDF !important;
//     box-shadow: none !important;
// }

.navbar-white-fixed .menus>.menu-items>a,
.navbar-white-fixed .menus>.menu-items>button {
    // color: #000 !important;
    color: var(--menu-item-btn-black) !important;
}

.navbar-white-fixed .text-styles {
    // color: #000 !important;
    color: var(--menu-item-btn-black) !important;
}

.navbar-white-fixed .menus>.menu-items>a:hover,
.navbar-white-fixed .menus>.menu-items>button:hover {
    // color: #0E97FF !important;
    color: var(--text-color-light-blue) !important;
}

.navbar-inner {
    width: 100%;
}

.navbar-center {
    display: flex;
    align-items: center;
    padding: 17px 0px 12px;
}

.profile-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 15px;
}


.search-icon-header {
    position: relative;
    top: 2px;
}

.sidebar-list-text span {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    // color: #212121;
    color: var(--sidebar-list-span-text-color);
}

.sidebar-list-text:hover span {
    // color: #0E97FF;
    color: var(--text-color-light-blue);
}

.sidebar-collapse {
    background: rgba(14, 151, 255, 0.04);
    color: var(--sidebar-background);
}

.subitem-pad-left {
    padding-left: 20px;
}

.sidebar-drawer {
    width: 270px;
    padding: 0px 20px;
}

.profil-txt {
    color: #010E16 !important;
    font-family: "Lexend", sans-serif !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    padding-left: 9px;
    border-bottom: 1px solid #EEE !important;
    padding: 19px 0px !important;

}

.lang-Icon {
    position: absolute;
    bottom: 38px;
    z-index: 9999;
    left: 29px;
    width: 100px;
}

.profil-txt-rtl {
    color: #010E16 !important;
    font-family: "El Messiri" !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    padding-left: 9px;
    border-bottom: 1px solid #EEE !important;
    padding: 19px 0px !important;
    display: flex !important;
    justify-content: flex-end !important;
}


.sidebar-drawer .profil-txt:last-child {
    border-bottom: none !important;

}

.Drawer-profile {
    position: absolute;
    bottom: 55px;

}

.emp-corner-mobile {
    margin: 8px auto 0px;
    // border-top: 1px solid #e0dada;
    border-top: 1px solid var(--emp-corner-mobile-border-top);
    width: 100%;
    padding: 20px 20px 0px;
    box-sizing: border-box;
}

.emp-corner-mobile button {
    cursor: pointer;
    // background: rgb(14, 151, 255) !important;
    background: var(--btn-primary-blue-bg) !important;
    border-radius: 50px !important;
    // color: #fff !important;
    color: var(--text-color-white);
    font-weight: 500 !important;
    font-size: 13px !important;
    width: 100% !important;
    text-align: center !important;
    border: none !important;
    padding: 13px 12px !important;
    line-height: normal;
}

.left-social-mobile ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 8px 0px 0px;
    padding: 10px 0px;
    justify-content: center;
}

.left-social-mobile ul li {
    margin-right: 10px;
    margin-bottom: 10px;
}

.left-social-mobile ul li:last-child {
    margin-right: 0px;
}

.login-btn {
    cursor: pointer;
    // background-color: rgb(0, 0, 0) !important;
    background-color: var(--login-btn-background-color) !important;
    // color: rgb(255, 255, 255) !important;
    color: var(--login-btn-text) !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    padding: 9px 18px 4px !important;
    border-radius: 0px !important;
    // border: 1px solid rgb(255, 255, 255) !important;
    border: 1px solid var(--login-btn-text) !important;
}

.login-btn:hover {
    cursor: pointer;
    // background-color: #0E97FF !important;
    background-color: var(--text-color-light-blue) !important;
    // border: 1px solid #0E97FF !important;
    border: 1px solid var(--text-color-light-blue) !important;
}

.top-bar-contct {
    background: #1B2132;
    padding: 12px 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 2;
}

.top-bar-contact-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;
    align-items: center;
    list-style-type: none;
}

.top-bar-contact-list li {
    margin-right: 20px;
    // color: #FFFFFF;
    color: var(--text-color-white);
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: initial;
}

.top-bar-contact-list li:last-child {
    margin-right: 0px !important;
}

.top-bar-contact-list .top-bar-contct-icon {
    margin-right: 10px;
}

.position-fixed {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 5;
}

.header-navbar-subpage {
    // border-bottom: 1px solid #f3f3f3;
    border-bottom: 1px solid var(--header-navbar-subpage-border);
    box-shadow: 0px 5px 4px rgba(0, 0, 0, .1) !important;
}

.sub-page-top-header-spacer {
    height: 138px;
}

.header-navbar-subpage-top {
    background-color: #FFFFFF !important;
    box-shadow: none !important;
    position: fixed !important;
    left: 0px !important;
    top: 45px !important;
    width: 100% !important;
    transition: all 0.5s ease !important;
    -webkit-transition: all 0.5s ease !important;
    z-index: 50 !important;
}

.header-navbar-subpage-top .navbar-center {
    padding: 12px 0px 8px;
}

.header-navbar-subpage-top-fixed {
    transition: all 0.5s ease !important;
    -webkit-transition: all 0.5s ease !important;
    background-color: #FFFFFF !important;
    position: fixed !important;
    left: 0px;
    top: 0px;
    width: 100%;
    box-shadow: none !important;
    z-index: 50 !important;
}

.header-navbar-subpage .menus>.menu-items>a,
.header-navbar-subpage .menus>.menu-items>button {
    // color: #000 !important;
    columns: var(--menu-item-btn-black) !important;
}

.header-navbar-subpage .menus>.menu-items>a:hover,
.header-navbar-subpage .menus>.menu-items>button:hover {
    // color: #0E97FF !important;
    color: var(--text-color-light-blue) !important;
}

.header-navbar-subpage .display-emp button {
    background: #FFFFFF !important;
    border: 1px solid var(--color-gainsboro) !important;
    box-shadow: none !important;
}

.drawer-outer {
    padding-left: 20px;
}

.drawer-logo {
    margin-left: 20px;
    margin-top: 86px;
    margin-bottom: 22px;

}

.drawer-logo img {
    width: 60px;
}

.header-height {
    height: 83px;
}

.prof-icon {
    padding-right: 20px;
}

.prof-icon-rtl {
    padding-left: 20px;
}


.profile-container {
    align-items: center;
    display: flex;
    padding: 20px 20px 20px 20px;
    flex-flow: nowrap;
}

.profile-avatar {
    margin-right: 16px;
    /* Adjust spacing as needed */
}

.profile-details {
    display: flex;
    flex-direction: column;
}

@media (max-width: 1280px) {
    .top-bar-contct {
        display: none;
    }

    .header-height {
        height: 64px;
    }

    .sub-page-top-header-spacer {
        height: 74px;
    }

    .header-navbar-subpage-top {
        top: 0px !important;
    }

    .navbar-center {
        padding: 12px 0px 8px !important;
    }

    // .display-emp {
    //     display: none;
    // }

    .contact-bar-off {
        display: none !important;
    }
}


.language-toggle-btn {
    cursor: pointer !important;
    display: flex !important;
    height: 30px;
    padding: 6px 13px !important;
    justify-content: center !important;
    align-items: flex-start !important;
    gap: 6px !important;
    flex-shrink: 0;
    border-radius: 22.384px !important;
    background: rgba(255, 255, 255, 0.20) !important;
    backdrop-filter: blur(5.484133720397949px) !important;
    border: none;
    font-family: var(--body-family);
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    display: flex;
    justify-content: end;
    // width: 100%;
    margin-top: 30px;
    cursor: pointer;
    // display: flex !important;
    // right: 45px !important;
    // height: 28px !important;
    // padding: 4px 24px !important;
    // justify-content: center !important;
    // align-items: center !important;
    // gap: 10px !important;
    // flex-shrink: 0 !important;
    // border-radius: 56px !important;
    // border: 1px dashed #FFF !important;
    // background: rgba(255, 255, 255, 0.20) !important;
    // box-shadow: 0px 4px 4px 0px rgba(55, 125, 204, 0.20) !important;
    // backdrop-filter: blur(2px) !important;
}

.lang-btn-div {
    display: flex;
    justify-content: end;
    width: 100%;
    // margin-top: 30px;
    cursor: pointer;
}

.language-toggle-btn-text {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    // color: #010E16 !important;
    // text-align: center !important;
    // font-family: Lexend !important;
    // font-size: 11px !important;
    // font-style: normal !important;
    // font-weight: 300 !important;
    // line-height: 24px !important;
}

// .lang-main-div {
//     // border-radius: 40px 40px 0px 0px;
//     // background: #FFF;
//     width: 390px; //466
//     border: 1px solid var(--card-div-gains-boro);
//     z-index: 200;
//     padding: 2px 25px 14px;
//     box-sizing: border-box;
//     border-radius: 20px;
//     background: #FFF;
//     box-shadow: none !important;
//     height: 617px;
// }

.country-title {
    color: #0A2533;
    font-family: var(--body-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.country-title-rtl {
    font-family: var(--font-Messiri) !important;
}

.btn-langs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    // margin-top: 20px;
}

.cnfrm-lang {
    // padding: 13.06px 48.9px 13.06px 49.65px;
    padding: 11px 51px;
    // padding: 9px 50px;
    cursor: pointer;
    background: #009DE0;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    border: none;
    // width: 223px;
    font-family: var(--body-family);
    cursor: pointer;
    border-radius: 50px;
    margin-right: 20px;
}

.cnfrm-lang-rtl {
    font-family: var(--font-Messiri);
    font-weight: 700 !important;
    padding: 9px 64px !important;
}




.solid-line-class {
    padding: 2px 0px;
}

.solid-line-class:nth-child(1) {
    border-bottom: 1px solid #EBEBEB;
}

.solid-line-off {
    border-bottom: none;
}

.radio-form-main {
    // margin-bottom: 30px;
    margin-left: 0px !important;
    // border-bottom: 1px dotted #EBEBEB;
    // padding-bottom: 15px;
    margin-right: 0px !important;
    position: relative;
    padding: 20px 0px 20px 0px;
}

.radio-form-main:before {
    content: '';
    position: absolute;
    left: 61px;
    top: 100%;
    border-bottom: 1px dotted #EBEBEB;
    display: block;
    width: 80%;
    height: 1px;
}

.radio-form-main-rtl {
    // position: relative;
    // border-bottom: 1px solid #EBEBEB;
    // margin-bottom: 0px;
    // display: flex !important;
    // align-items: center !important;
    // // padding: 13px 0px;
    // padding: 13px 0px 0px 0px;
    // flex-direction: row-reverse;
}

.radio-form-main-left {
    position: relative;
    // border-bottom: 1px dotted #EBEBEB;
    // margin-bottom: 15px;
    display: inline-flex !important;
    align-items: center !important;
    // padding: 15px 0px 15px 0px;
    // padding-bottom: 15px;
    margin-right: 20px !important;
    padding: 20px 0px 20px 0px;

}
.radio-form-main-left:before {
    content: '';
    position: absolute;
    // left: 61px;
    top: 100%;
    border-bottom: 1px dotted #EBEBEB;
    display: block;
    width: 80%;
    height: 1px;
}

.flg-div {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: grey;
    margin-left: 10px;
}

.flag-inner-div {
    display: flex;
    align-items: center;
    text-align: center;
}

.flag-inner-div-left {
    position: absolute;
    top: 2px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    direction: rtl;
    // align-items: center;
    // justify-content: center;
}

.flag-icon {
    margin-right: 22px;
}

.flag-icon-rtl {
    margin-left: 22px;
    margin-right: -13px;
    margin-top: 10px;
}

.lng-text {
    color: #009DE0;
    text-align: center;
    margin: 30px 0px 15px 0px;
    // font-family: Outfit;
    font-family: var(--body-family);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.lng-text-rtl {
    font-family: var(--font-Messiri);
    font-weight: 700;
    padding: 9px 64px !important;
}

.cncel-lng {
    padding: 10.06px 55.28px;
    font-size: 16px;
    cursor: pointer;
    color: #FFF;
    text-align: center;
    font-family: var(--body-family);
    cursor: pointer;
    border-radius: 50px;
    border: 1px solid #009DE0;
    background: #FFF;
    color: #009DE0;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    // padding: 13.06px 54.28px 13.06px 54.28px;
}

.cncel-lng-rtl {
    font-family: var(--font-Messiri) !important;
    padding: 12px 65px !important;
}

@media(max-width: 1199px) {
    .navbar-transparent {
        background: rgba(0, 0, 0, .69) !important;
        background: var(--navbar-transparent) !important;
        box-shadow: none !important;
    }

    .login-btn {
        font-size: 12px !important;
        padding: 9px 12px 6px !important;
    }
}

@media(max-width: 767px) {
    .header-height {
        height: 60px;
    }

    .container-styles {
        gap: 60px;
    }

    .display-emp button {
        font-size: 11px !important;
        // padding: 13px 3px !important;
    }

    .restaurant-image-div {
        height: 158px;
        min-height: 158px;
    }
}

@media (max-width: 350px) {
    .display-emp button {
        font-size: 7px !important;
        padding: 13px 0px !important;
    }
}