*,
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    /* --font-Messiri: "Almarai", sans-serif !important; */
}

html,
body {
    height: 100%;
}
body {
    margin: 0 !important;
    /* font-family: var(--body-family), sans-serif !important;
    font-family: var(--font-Messiri), sans-serif !important; */
    font-style: normal;
}

.MuiTypography-body1 {
    font-family: var(--body-family), sans-serif !important;
}

.MuiTypography-body2 {
    font-family: var(--body-family), sans-serif !important;
}

.MuiTypography-h1,
.MuiTypography-h2,
.MuiTypography-h3,
.MuiTypography-h4,
.MuiTypography-h6,
.MuiTypography-h5 {
    font-family: var(--body-family), sans-serif !important;
}


/* body {
    margin: 0 !important;
    /* --font-Outfit:"Outfit",sans-serif !important; */
    /* font-family: "Outfit", sans-serif !important; */
    /* font-style: normal; */
/* } */ 

/* .MuiTypography-body1 {
    font-family: "Outfit", sans-serif !important;

} */

/* .MuiTypography-body2 {
    font-family: "Outfit", sans-serif !important;
} */

/* .MuiTypography-h1,
.MuiTypography-h2,
.MuiTypography-h3,
.MuiTypography-h4,
.MuiTypography-h6,
.MuiTypography-h5 {
    font-family: "Outfit", sans-serif !important;

} */


.MuiAppBar-colorPrimary {
    /* background-color: #1B2132 !important; */
}

.MuiButton-textPrimary {
     /* color: #1B2132 !important; */
}

.main-root-center {
    height: 100%;
    max-width: 540px;
    margin: 0px auto;
    /* background-size: 100% 100%; */
    background-repeat: repeat;
    background-position: center top;
}

/* @font-face {
    font-family: 'Rajdhani';
    src: local('Rajdhani'), url(./fonts/glyphicons-halflings-regular.ttf) format('truetype');
} */

/* @font-face {
    font-family:"Syne", sans-serif;
    src: local('Lato'), url(./fonts/RobotoCondensed-Regular.ttf) format('opentype');
    src: local('Lato'), url('./fonts/RobotoCondensed-Regular.ttf') format('opentype');
} */